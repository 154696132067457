import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/ui/Hero/Hero";
import Section from "../components/ui/Section/Section";
import classnames from 'classnames';
import SearchBox from "../components/Search/SearchBox";
import SearchResults from "../components/Search/SearchResults";
import SearchIllustration from "../assets/suche.svg"

import * as styles from "./suche.module.scss";

export default () => {

    return (
    <Layout crumbs={[{ label: "Home", url: "/" }, { label: "Suche", url: "/suche" }]} hasWave={false} breadcrumbColor="light">
        <Hero
            color="dark"
            hasWaveBottom={["mobile", "tablet"]}
        >
            <div className="columns">
                <div className="column">
                    <div className="content content-small">
                        <h1 className="title">Das richtige finden</h1>
                        <p>Durchsuchen Sie unsere Seite und finden Sie das Richtige aus unseren Produkten oder aus unseren Projekten über die Fallbeispiele</p>
                    </div>
                </div>
                <div className={classnames(["column is-one-fifth", "is-hidden-mobile", styles.illustration])}>
                    <img src={SearchIllustration} alt="Suche auf unserer Website nach Produkten" />
                </div>
            </div>
        </Hero>

        <div className={classnames(["section", styles.searchBox])} >
            <div className={"container"}>
                <div className="columns">
                    <div className="column is-1 is-hidden-touch"></div>
                    <div className="column is-three-fifths-desktop">
                        <SearchBox />
                    </div>
                </div>
            </div>
        </div>
           
        <Section size="small">
            <SearchResults />
        </Section>

    </Layout>
)}
